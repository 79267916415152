// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #1d91f4;
  --ion-color-primary-rgb: 29, 145, 244;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #1a80d7;
  --ion-color-primary-tint: #349cf5;

  /** secondary **/
  --ion-color-secondary: #8cc8fb;
  --ion-color-secondary-rgb: 140,200,251;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #7bb0dd;
  --ion-color-secondary-tint: #98cefb;

  /** tertiary **/
  --ion-color-tertiary: #c5e4ff;
  --ion-color-tertiary-rgb: 197,228,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #adc9e0;
  --ion-color-tertiary-tint: #cbe7ff;

  /** success **/
  --ion-color-success: #3880ff;
  --ion-color-success-rgb: 56,128,255;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #3171e0;
  --ion-color-success-tint: #4c8dff;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #ff7484;
  --ion-color-danger-rgb: 255,116,132;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e06674;
  --ion-color-danger-tint: #ff8290;

  /** dark **/
  --ion-color-dark: #312f2f;
  --ion-color-dark-rgb: 49,47,47;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #2b2929;
  --ion-color-dark-tint: #464444;

  /** medium **/
  --ion-color-medium: #acacac;
  --ion-color-medium-rgb: 172,172,172;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #979797;
  --ion-color-medium-tint: #b4b4b4;

  /** light **/
  --ion-color-light: #f4f4f4;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d7d7;
  --ion-color-light-tint: #f5f5f5;

  /* Set the font family of the entire app */
  --ion-font-family: 'M PLUS Rounded 1c', sans-serif;

  /* tab-bar, toolbar */
  --ion-toolbar-background: #f7f7f7;
  --ion-tab-bar-background: #f7f7f7;
}

// dark mode 対応保留
// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body {
//     --ion-color-primary: #1d91f4;
//     --ion-color-primary-rgb: 29, 145, 244;
//     --ion-color-primary-contrast: #ffffff;
//     --ion-color-primary-contrast-rgb: 255,255,255;
//     --ion-color-primary-shade: #1a80d7;
//     --ion-color-primary-tint: #349cf5;

//     --ion-color-secondary: #8cc8fb;
//     --ion-color-secondary-rgb: 140,200,251;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255,255,255;
//     --ion-color-secondary-shade: #7bb0dd;
//     --ion-color-secondary-tint: #98cefb;

//     --ion-color-tertiary: #c5e4ff;
//     --ion-color-tertiary-rgb: 197,228,255;
//     --ion-color-tertiary-contrast: #ffffff;
//     --ion-color-tertiary-contrast-rgb: 255,255,255;
//     --ion-color-tertiary-shade: #adc9e0;
//     --ion-color-tertiary-tint: #cbe7ff;

//     --ion-color-success: #3880ff;
//     --ion-color-success-rgb: 56,128,255;
//     --ion-color-success-contrast: #ffffff;
//     --ion-color-success-contrast-rgb: 255, 255, 255;
//     --ion-color-success-shade: #3171e0;
//     --ion-color-success-tint: #4c8dff;

//     --ion-color-warning: #ffd534;
//     --ion-color-warning-rgb: 255,213,52;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0,0,0;
//     --ion-color-warning-shade: #e0bb2e;
//     --ion-color-warning-tint: #ffd948;

//     --ion-color-danger: #ff7484;
//     --ion-color-danger-rgb: 255,116,132;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255,255,255;
//     --ion-color-danger-shade: #e06674;
//     --ion-color-danger-tint: #ff8290;

//     --ion-color-dark: #f4f4f4;
//     --ion-color-dark-rgb: 244,244,244;
//     --ion-color-dark-contrast: #000000;
//     --ion-color-dark-contrast-rgb: 0,0,0;
//     --ion-color-dark-shade: #d7d7d7;
//     --ion-color-dark-tint: #f5f5f5;

//     --ion-color-medium: #acacac;
//     --ion-color-medium-rgb: 172,172,172;
//     --ion-color-medium-contrast: #000000;
//     --ion-color-medium-contrast-rgb: 0,0,0;
//     --ion-color-medium-shade: #979797;
//     --ion-color-medium-tint: #b4b4b4;

//     --ion-color-light: #312f2f;
//     --ion-color-light-rgb: 49,47,47;
//     --ion-color-light-contrast: #ffffff;
//     --ion-color-light-contrast-rgb: 255,255,255;
//     --ion-color-light-shade: #2b2929;
//     --ion-color-light-tint: #464444;
//   }

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//   .ios body {
//     --ion-background-color: #000000;
//     --ion-background-color-rgb: 0,0,0;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;

//     --ion-color-step-50: #0d0d0d;
//     --ion-color-step-100: #1a1a1a;
//     --ion-color-step-150: #262626;
//     --ion-color-step-200: #333333;
//     --ion-color-step-250: #404040;
//     --ion-color-step-300: #4d4d4d;
//     --ion-color-step-350: #595959;
//     --ion-color-step-400: #666666;
//     --ion-color-step-450: #737373;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #8c8c8c;
//     --ion-color-step-600: #999999;
//     --ion-color-step-650: #a6a6a6;
//     --ion-color-step-700: #b3b3b3;
//     --ion-color-step-750: #bfbfbf;
//     --ion-color-step-800: #cccccc;
//     --ion-color-step-850: #d9d9d9;
//     --ion-color-step-900: #e6e6e6;
//     --ion-color-step-950: #f2f2f2;

//     // --ion-item-background: #000000;

//     --ion-card-background: #1c1c1d;
//   }

//   .ios ion-modal {
//     --ion-background-color: var(--ion-color-step-100);
//     --ion-toolbar-background: var(--ion-color-step-150);
//     --ion-toolbar-border-color: var(--ion-color-step-250);
//   }


//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   .md body {
//     --ion-background-color: #121212;
//     --ion-background-color-rgb: 18,18,18;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;

//     --ion-border-color: #222222;

//     --ion-color-step-50: #1e1e1e;
//     --ion-color-step-100: #2a2a2a;
//     --ion-color-step-150: #363636;
//     --ion-color-step-200: #414141;
//     --ion-color-step-250: #4d4d4d;
//     --ion-color-step-300: #595959;
//     --ion-color-step-350: #656565;
//     --ion-color-step-400: #717171;
//     --ion-color-step-450: #7d7d7d;
//     --ion-color-step-500: #898989;
//     --ion-color-step-550: #949494;
//     --ion-color-step-600: #a0a0a0;
//     --ion-color-step-650: #acacac;
//     --ion-color-step-700: #b8b8b8;
//     --ion-color-step-750: #c4c4c4;
//     --ion-color-step-800: #d0d0d0;
//     --ion-color-step-850: #dbdbdb;
//     --ion-color-step-900: #e7e7e7;
//     --ion-color-step-950: #f3f3f3;

//     // --ion-item-background: #1e1e1e;

//     --ion-toolbar-background: #1f1f1f;

//     --ion-tab-bar-background: #1f1f1f;

//     --ion-card-background: #1e1e1e;
//   }
// }

// App Shared Variables
:root {
  --app-guide-height: 108px;
  --app-text-color: #393d40;
  --app-background-color: #dff1ff;
  --app-item-background: #ffffff;
}

// dark mode 対応保留
// @media (prefers-color-scheme: dark) {
//   :root {
//     --app-background-color: #000000;
//     --app-item-background: #0d1926;
//   }
// }