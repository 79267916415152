/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* Font */
@font-face {
  font-family: 'M PLUS Rounded 1c';
  font-weight: 100;
  font-style: normal;
  src: url('./assets/fonts/MPLUSRounded1c-Thin.ttf') format("truetype");
}
@font-face {
  font-family: 'M PLUS Rounded 1c';
  font-weight: 300;
  font-style: normal;
  src: url('./assets/fonts/MPLUSRounded1c-Light.ttf') format("truetype");
}
@font-face {
  font-family: 'M PLUS Rounded 1c';
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/MPLUSRounded1c-Regular.ttf') format("truetype");
}
@font-face {
  font-family: 'M PLUS Rounded 1c';
  font-weight: 500;
  font-style: normal;
  src: url('./assets/fonts/MPLUSRounded1c-Medium.ttf') format("truetype");
}
@font-face {
  font-family: 'M PLUS Rounded 1c';
  font-weight: bold;
  font-style: normal;
  src: url('./assets/fonts/MPLUSRounded1c-Bold.ttf') format("truetype");
}
@font-face {
  font-family: 'M PLUS Rounded 1c';
  font-weight: 800;
  font-style: normal;
  src: url('./assets/fonts/MPLUSRounded1c-ExtraBold.ttf') format("truetype");
}
@font-face {
  font-family: 'M PLUS Rounded 1c';
  font-weight: 900;
  font-style: normal;
  src: url('./assets/fonts/MPLUSRounded1c-Black.ttf') format("truetype");
}

html {
  font-size: 62.5%;
  color: var(--app-text-color);
}

@media screen and (max-width: 320px) and (max-height: 568px) {
  html {
    font-size: 54%;
  }
}

@media screen and (min-width: 360px) and (max-width: 374px) and (max-height: 640px) {
  html {
    font-size: 58%;
  }
}

@media screen and (min-width: 768px) and (min-height: 1024px) {
  html {
    font-size: 72%;
  }
}

@media screen and (min-width: 1024px) and (min-height: 1366px) {
  html {
    font-size: 80%;
  }
}

body {
  --ion-text-color: #393d40;
  --ion-text-color-rgb: 57, 61, 64;
}

ion-content {
  --ion-background-color: var(--app-background-color);
  --ion-text-color: #393d40;
  --ion-text-color-rgb: 57, 61, 64;
}

ion-content.md {
  ion-list {
    padding-top: 0;
  }
}

/* guide.component innerHTML class */
.message {
  .description {

    .attention1 {
      font-weight: 600;
      color: var(--ion-color-primary);
    }

    .attention2 {
      font-weight: 600;
      color: var(--ion-color-danger);
    }
  }
}

/* Lesson Part innerHTML class */
ion-content.lesson-part {
  .comment-style,
  .point-panel {

    .bold-text {
      font-weight: bold;
    }
  
    .question {
      font-size: 2.0rem;
    }
  
    .attention1 {
      font-weight: 600;
      color: var(--ion-color-primary);
    }
    .attention2 {
      font-weight: 600;
      color: var(--ion-color-danger);
    }

    .attention3 {
      font-weight: 500;
      color: #7565f0;
    }
  
    .attention4 {
      font-weight: 500;
      color: #f06575;
    }
  
    .link {
      text-decoration: underline;
      color: var(--ion-color-primary);
  
      &:active {
        color: var(--ion-color-danger);
      }
    }
  }
}

/* 睡眠日記 詳細 */
ion-content.bi-worksheet-detail {
  .sleep-efficiency, .advice, .objective-setting {
    .comment, .sub-message {
      .nap {
        display: inline;
        color: rgb(252, 64, 158);
        font-weight: bold;
      }
  
      .time {
        margin: 0 0.5rem;
        font-size: 1.6rem;
        font-weight: 700;
        color: #488aff;
      }
  
      .type1 {
        display: inline;
        color: rgb(255, 133, 193);
        font-weight: bold;
      }
  
      .type2 {
        display: inline;
        color: rgb(51, 194, 193);
        font-weight: bold;
      }
    }
  }
}

/* Worksheet Edit class */
ion-textarea.worksheet-edit {
  background: var(--app-item-background);
  padding-top: 13px;

  textarea::placeholder {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 700 !important;
    padding-top: 10% !important;
  }
}

/* Large alert */
.large-alert-class {
  --width: 90%;
  --max-width: 90%;
}
.large-alert-input-class {
  height: 66px !important;

  .alert-checkbox-icon {
    margin-left: 12px;
  }

  .alert-checkbox-label {
    overflow-wrap: break-word;
    white-space: normal;
  }

  .alert-checkbox-label.sc-ion-alert-ios {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 4px;
    padding-right: 4px;
  }
}

/* 時間入力モーダル */
ion-modal#datetime-modal {
  --height: auto;
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);

  ion-datetime {
    --background: transparent;
  }
}

/* フルライセンス表示 */
.full-license {
  .alert-wrapper {
    max-width: 90%;

    .alert-message {
      max-height: 100%;
      white-space: pre-wrap;
      text-align: left;
    }  
  }
}
